/* Generated by Font Squirrel (https://www.fontsquirrel.com) on July 6, 2016 */



@font-face {
    font-family: 'abchanel_pb_l';
    src: url('abchanel-pb-regular-l-webfont.woff2') format('woff2'),
         url('abchanel-pb-regular-l-webfont.woff') format('woff'),
         url('abchanel-pb-regular-l-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'abchanel_pb_m';
    src: url('abchanel-pb-regular-m-webfont.woff2') format('woff2'),
         url('abchanel-pb-regular-m-webfont.woff') format('woff'),
         url('abchanel-pb-regular-m-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'abchanel_pb_s';
    src: url('abchanel-pb-regular-s-webfont.woff2') format('woff2'),
         url('abchanel-pb-regular-s-webfont.woff') format('woff'),
         url('abchanel-pb-regular-s-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'abchanel_pb_l';
    src: url('abchanel-pb-semibold-l-webfont.woff2') format('woff2'),
         url('abchanel-pb-semibold-l-webfont.woff') format('woff'),
         url('abchanel-pb-semibold-l-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'abchanel_pb_m';
    src: url('abchanel-pb-semibold-m-webfont.woff2') format('woff2'),
         url('abchanel-pb-semibold-m-webfont.woff') format('woff'),
         url('abchanel-pb-semibold-m-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'abchanel_pb_s';
    src: url('abchanel-pb-semibold-s-webfont.woff2') format('woff2'),
         url('abchanel-pb-semibold-s-webfont.woff') format('woff'),
         url('abchanel-pb-semibold-s-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'abchanel_pb';
    src: url('abchanel-pb-regular-m-webfont.woff2') format('woff2'),
         url('abchanel-pb-regular-m-webfont.woff') format('woff'),
         url('abchanel-pb-regular-m-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'abchanel_pb';
    src: url('abchanel-pb-semibold-m-webfont.woff2') format('woff2'),
         url('abchanel-pb-semibold-m-webfont.woff') format('woff'),
         url('abchanel-pb-semibold-m-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}