
@font-face {
  font-family: 'futura';
  font-style: normal;
  font-weight: 300;
  src: local('Futura Std Light'), url('FuturaStdLight.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: italic;
  font-weight: 300;
  src: local('Futura Std Light Oblique'), url('FuturaStdLightOblique.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: normal;
  font-weight: 400;
  src: local('Futura Std Book'), url('FuturaStdBook.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: italic;
  font-weight: 400;
  src: local('Futura Std Book Oblique'), url('FuturaStdBookOblique.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: normal;
  font-weight: 500;
  src: local('Futura Std Medium'), url('FuturaStdMedium.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: italic;
  font-weight: 500;
  src: local('Futura Std Medium Oblique'), url('FuturaStdMediumOblique.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: normal;
  font-weight: 700;
  src: local('Futura Std Bold'), url('FuturaStdBold.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: italic;
  font-weight: 700;
  src: local('Futura Std Bold Oblique'), url('FuturaStdBoldOblique.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: normal;
  font-weight: 800;
  src: local('Futura Std Extra Bold'), url('FuturaStdExtraBold.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: italic;
  font-weight: 800;
  src: local('Futura Std Extra Bold Oblique'), url('FuturaStdExtraBoldOblique.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: normal;
  font-weight: 900;
  src: local('Futura Std Heavy'), url('FuturaStdHeavy.woff') format('woff');
}
  

@font-face {
  font-family: 'futura';
  font-style: italic;
  font-weight: 900;
  src: local('Futura Std Heavy Oblique'), url('FuturaStdHeavyOblique.woff') format('woff');
}
  
/* CONDENSED */

@font-face {
  font-family: 'futura-condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Futura Std Light Condensed'), url('FuturaStdCondensedLight.woff') format('woff');
}
  

@font-face {
  font-family: 'futura-condensed';
  font-style: italic;
  font-weight: 300;
  src: local('Futura Std Light Condensed Oblique'), url('FuturaStdCondensedLightObl.woff') format('woff');
}
  

@font-face {
  font-family: 'futura-condensed';
  font-style: normal;
  font-weight: 500;
  src: local('Futura Std Medium Condensed'), url('FuturaStdCondensed.woff') format('woff');
}
  

@font-face {
  font-family: 'futura-condensed';
  font-style: italic;
  font-weight: 500;
  src: local('Futura Std Medium Condensed Oblique'), url('FuturaStdCondensedOblique.woff') format('woff');
}
  

@font-face {
  font-family: 'futura-condensed';
  font-style: normal;
  font-weight: 700;
  src: local('Futura Std Bold Condensed'), url('FuturaStdCondensedBold.woff') format('woff');
}
  

@font-face {
  font-family: 'futura-condensed';
  font-style: italic;
  font-weight: 700;
  src: local('Futura Std Bold Condensed Oblique'), url('FuturaStdCondensedBoldObl.woff') format('woff');
}
  

@font-face {
  font-family: 'futura-condensed';
  font-style: normal;
  font-weight: 800;
  src: local('Futura Std Extra Bold Condensed'), url('FuturaStdCondensedExtraBd.woff') format('woff');
}
  

@font-face {
  font-family: 'futura-condensed';
  font-style: italic;
  font-weight: 800;
  src: local('Futura Std Extra Bold Condensed Oblique'), url('FuturaStdCondExtraBoldObl.woff') format('woff');
}