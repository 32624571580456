/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 25, 2018 */



@font-face {
    font-family: 'mllescript';
    src: url('mllescript-webfont.woff2') format('woff2'),
         url('mllescript-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}