
@import url(../fonts/futura-std/style.css);
@import url(../fonts/abchanel-pb/stylesheet.css);
@import url(../fonts/mllescript/stylesheet.css);
@import url(../styles/jquery-ui-theme-2020-1/jquery-ui.min.css);

.sf-toolbar { position: fixed; z-index: 99999; }
.sf-minitoolbar { bottom: 7px!important; right: 5px!important; border-radius: 50%!important; }
.sf-minitoolbar button { cursor: pointer; }

/* bugfix normalize with futura font */
html { line-height: normal; }

/* jquery ui */
.ui-dialog { padding:0; }
.ui-dialog .ui-dialog-titlebar { font-family:'abchanel_pb' !important; text-transform:uppercase; font-size: 10px !important; border-color: #000; background-color: #000; color: #fff; }
.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close { border: none; background: none; margin: -20px -7px 0 0; width: 40px; height: 40px; }
.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close .ui-icon { background-image: url(../medias/global/close-button-reverse.png); background-size: 100% 100%; background-position: 0 0; }
.ui-dialog .ui-dialog-buttonpane { border-width: 0; text-align: center; }
.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset { float: none; }
.ui-dialog .ui-dialog-content { padding: 1em; }
.ui-dialog-buttonset button { font-family:'abchanel_pb' !important; text-transform:uppercase; font-size: 9px !important; background-color: #000 !important; color: #fff !important; border-color: #000 !important; }
button:focus { outline:0; }
.ui-datepicker-month { color: #000; }
.ui-datepicker-year { color: #000; }
.ui-front { z-index: 10000; }
.no-close .ui-dialog-titlebar-close { display: none; }
.no-close .ui-dialog-title { width: 100%; }

.ui-widget { font-family: futura, Arial,sans-serif; font-size: 1.1em; }
.ui-widget .ui-widget { font-size: 1.1em; }
.ui-widget button { font-family: futura, Arial,sans-serif; font-size: 1.1em; }


.button, .button-reverse { background:#000; color:#FFF; border:#000 solid 1px; font-size:11px; cursor:pointer; text-decoration:none; font-family:'abchanel_pb'; text-transform:uppercase; font-weight: 200; padding:7px 20px; display: inline-block; line-height: normal; }
.button-reverse { background:#FFF; color:#000; }
.button.animate-hover { transition: background-color linear 0.15s, color linear 0.15s, border-color linear 0.15s; }
.button.animate-hover:hover { background: #fff; color: #000; }
.button-reverse.animate-hover:hover { background:#fff; color:#000; border-color: #adadad; }


INPUT.input-text, SELECT.input-text, TEXTAREA.input-text { background-color:#FFF; border:#d7d7d7 solid 1px; font-size:12px; padding:2px; }
SELECT.input-text { padding: 1px; }
INPUT.error { border-color: red !important; border-style: dotted !important; }
TEXTAREA.error { border-color: red !important; border-style: dotted !important; }
input:-webkit-autofill { -webkit-box-shadow:0 0 0 500px white inset; }
textarea, select, input, *:focus { outline: none !important; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none; margin: 0; }


.popin { display: none; }
.popin-default-wrap .fancybox-content { background-color: #ffffff; color: #000; text-align: center; box-sizing: content-box; }
.popin-default-wrap .text { margin-top: 40px; margin-bottom: 40px; }
.popin-default-wrap .buttons .button { margin-bottom: 10px; }
.popin-full-wrap .fancybox-content { background-color: #ffffff; color: #000; text-align: center; max-width: none; width: 100%; height: 100%; }

.fancybox-content { padding: 0; background: #000; color: #fff; width: 96%; }
.fancybox-close-small { background: rgba(203,203,203,.55); color: #000; padding: 0; z-index: 8001; /* on top of .responsive-youtube-container iframe */ }
.fancybox-slide--html .fancybox-close-small { color: #000; padding: 0; }

@media (min-width: 800px) {
  .fancybox-content { width: 60%; }
}

.imageuploader-container { max-width: 660px; margin-left: auto; margin-right: auto; text-align: center; background-color: #fff; color: #000; display: none; }
.imageuploader-container .step-upload .upload-file { margin: 30px 0; }
.imageuploader-container .step-upload .upload-file .fileinput-button { display: block; width: 250px; height: 90px; margin-left: auto; margin-right: auto; background-color: #000; color: #fff; font-family:'abchanel_pb'; text-transform:uppercase; }
.imageuploader-container .step-upload .upload-file .fileinput-button input { max-height: 100%; }
.imageuploader-container .step-upload .upload-file .fileinput-button .fileinput-text { display: block; margin-top: 20px; }
.imageuploader-container .step-upload .upload-file .fileinput-button .fileinput-icon { display: block; font-size: 30px; }
.imageuploader-container .step-upload .upload-file .format { display: block; font-size: 9px; margin-top: 10px; }

.imageuploader-container .step-crop .crop { margin: 30px 0; }
.imageuploader-container .step-crop .crop .crop-center { visibility:hidden; margin: 0 auto; }
.imageuploader-container .step-crop .crop .crop-center .jcrop-holder { margin: 0 auto; } /* center horizontally */
.imageuploader-container .step-crop .crop .crop-center .crop-image { max-width: 100%; }
.imageuploader-container .step-crop .crop .crop-loading { margin: 30px 0; display:none; color: #ffffff; text-align: center; }

.imageuploader-container .progress { display: block; width: 40%; margin-left: auto; margin-right: auto; margin-top: 12px; background-color: #ffffff; border: #d7d7d7 solid 1px; }
.imageuploader-container .progress .progress-bar { display: block; background-color: #bdbdbd; height: 11px; width: 0; }
.imageuploader-container .loading { margin: 30px 0; display:none; }
.imageuploader-container .error { margin: 30px 0; font-weight:bold; display: none; }
.imageuploader-container .buttons { padding-top: 10px; padding-bottom: 10px; }

.imageuploader-container.fancybox-content { padding: 44px; }

/* select 2 fix */

.select2-container {
    z-index: 5999;
}

.modal .select2-container {
    z-index: 9999;
}
